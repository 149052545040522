<template>
  <div class="box p-3">
    <div class="d-flex justify-content-center flex-column align-items-center">
      <h3 class="title" >Usuário</h3>
      <span class="span_company">* nota individual por usuário</span>
    </div>
    <div v-if="!votes[0]">
      Selecione a empresa primeiro...
    </div>
    <table v-else class="table table-borderless">
      <thead>
        <tr>
          <th class="line" scope="col"><div class="head">Usuário</div></th>
          <th scope="col"><div class="head">Vote</div></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vote, index) of votes" :key="index">
          <td class="line">{{vote.name}}</td>
          <td>{{vote.vote}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    votes: {
      type: Array
    }
  }
}
</script>

<style scoped>
table {
  text-align: center;
  color: #8d8f92;
}

td {
  width: 150px;
}

.head {
  border: 1px solid #8d8f92;
  border-radius: 20px;
  padding: 10px;
  color: #8d8f92;
}

.line {
  border-right: 3px solid #8d8f92;
}

.box {
  width: 100%;
  max-width: 510px;
  background-color: #e6e7e9;
  border-radius: 20px;
  overflow: auto;
  max-height: 40vh;
}

.title {
  background-color: #37aae1;
  border-radius: 20px;
  width: 100%;
  max-width: 140px;
  height: 100%;
  min-height: 40px;
  text-align: center;
  padding-top: 5px;
  color: #fff;
  font-size: 1.5em;
}

.span_company{
  font-size: 0.7em;
}
</style>